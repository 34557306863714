import { Button, Grid, Paper, Typography } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";
import { Link } from "react-router-dom";
import { isMobile } from "../../hooks/isMobile";
import { routes } from "../../routes";

const useConnectStyles = makeStyles((theme: Theme) => ({
	registerPaper: {
		padding: "0px 50px",
		maxWidth: 500,
		[theme.breakpoints.down("xs")]: {
			registerPaper: {
				margin: "0px 20px",
				padding: "0px 30px",
			},
		},
	},
	registerButton: {
		backgroundColor: theme.palette.secondaryResponse.main,
	},
	responseBlueText: {
		paddingLeft: "0px",
		paddingRight: "0px",
		color: theme.palette.secondaryResponse.main,
	},
}));

export default function RegisterProfile() {
	const classes = useConnectStyles();
	const mobile = isMobile();

	return (
		<Grid style={{ height: "100vh", overflowY: "auto" }} container direction="column" justify="center" alignItems="center">
			<Grid item style={{ height: mobile ? "100%" : undefined}}>
				<Paper className={classes.registerPaper}>
					<Grid container direction="column" justify="center" alignItems="center">
						<div style={{ maxWidth: 350 }}>
							<img
								src="https://i.cdn-sc.com/Response/logos/logoWithName.png"
								style={{ maxWidth: "100%", height: "auto" }}
								alt="Response"
							/>
						</div>
					</Grid>
					<Typography style={{ marginTop: 10 }} variant="h3">We are no longer accepting new registrations at this time.</Typography>
					<Grid container direction="column" justify="center" alignItems="center">
						<Link
							to={`${routes.public.signInPage}?redirect=${routes.app.dashBoardPage}`}
							style={{ textDecoration: "none" }}
						>
							<Button
								variant="contained"
								size="medium"
								color="primary"
								type="submit"
								style={{ margin: "20px 0px 20px 0px" }}
							>
								Already have an account?
							</Button>
						</Link>
					</Grid>
				</Paper>
			</Grid>
		</Grid>
	);
}
